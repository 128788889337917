<template>
    <div>
       <v-card>
      <v-card-title>
        Quotations
        <v-spacer />
        <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
      </v-card-title>

      <v-data-table :headers="headers" :items="data" item-key="name" :search="search">
        <template v-slot:[`item.actions`]="{ item }">
        <a class="mr-5" :href="'/buyer/quotationDetail/' + item._id " target="_blank">View</a>
        </template>
      </v-data-table>
    </v-card>
    </div>
</template>

<script>
import RestResource from "../../services/dataServiceBuyer";
const service = new RestResource();

export default {
  data() {
    return {
     data:[],
    search: "",
     headers: [
        { text: "Email", value: "email", sortable: false },
        { text: "Company", value: "company", sortable: false },
        { text: "Country", value: "country", sortable: true },
        { text: "Product", value: "product", sortable: true },
        { text: "Date", value: "created_at", sortable: false },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },

  mounted() {
      this.fetchQuotations()
  },

  methods: {
    fetchQuotations() {
        this.$setLoader()
        service.fetchQuotations().then(res => {
            this.data = res.data.data
            this.$disableLoader()
        })
    }
  }
};
</script>